import React from 'react';
import Dashboard from './pages/dashboard/Dashboard.js';
import Quote from './pages/quote/Quote.js';
import Products from './pages/products/Products.js';
import { Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import Login from './pages/login/Login.js';
import Register from './pages/login/Register.js';
import ProtectedRoute from './utils/ProtectedRoute.js';


const RouteRoute = ({setLoader}) => {


  return (
    <>
    <Routes>
        <Route path='/login' element={<Login setLoader={setLoader}/>}></Route>
        <Route path='/register' element={<Register setLoader={setLoader}/>}></Route>  
        {/* <Route path='/' element={<Layout/>}>
        <Route path='/' element={<Dashboard setLoader={setLoader}/>}></Route>
        <Route path='/quote' element={<Quote setLoader={setLoader}/>}></Route>
        <Route path='/products' element={<Products setLoader={setLoader}/>}></Route>
      </Route> */}
      <Route path='/' element={<Layout setLoader={setLoader}/>}>
        <Route path='/' element={<ProtectedRoute><Dashboard setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/quote' element={<ProtectedRoute><Quote setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/products' element={<ProtectedRoute><Products setLoader={setLoader}/></ProtectedRoute>}></Route>
      </Route>
    </Routes>
    </>
  )
}

export default RouteRoute;