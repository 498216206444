import React, { useEffect, useState, useCallback } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import RouteRoute from "./Routes";
import Loader from "../src/components/loader/Loader.js";
import "./App.css";

import { useDispatch } from "react-redux";

import { logout } from "./slices/auth.js";

import EventBus from "./utils/EventBus.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clearMessage } from "./slices/message.js";
import { useSelector } from "react-redux";

function App() {
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  const { message } = useSelector((state) => state.message);
  const { message : message1 } = useSelector((state) => state.product);
  const { message : message2 } = useSelector((state) => state.quote);

  useEffect(()=>{
    message && toast.error(message)
    message1 && toast.success(message1)
    message2 && toast.success(message2)
    if((message || message1 || message2) === "Unautheticated"){
      window.location.reload();
    }
}, [message, message1, message2])

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [logOut]);

  return (
    <>
      <Router>
        {loader && <Loader></Loader>}
        <RouteRoute setLoader={setLoader} />
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Router>
    </>
  );
}

export default App;
