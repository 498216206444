import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const register = (name, email, password, password_confirmation) => {
  return axios.post(API_URL + "/register", {
    name,
    email,
    password,
    password_confirmation
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "/login", {
      email: username,
      password: password,
    })
    .then((response) => {
      if (response.data.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
        localStorage.setItem("token", JSON.stringify(response.data.data.token));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  window.location.reload();
  // return axios.post(API_URL + "/logout")
};

const authService = {
  register,
  login,
  logout,
};

export default authService;