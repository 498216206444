import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import ProductService from "../services/product.service";

import EventBus from "../utils/EventBus";


export const getproductall = createAsyncThunk(
  "product/getproductall",
  async ( thunkAPI) => {
    try {
      const data = await ProductService.getProductAll();
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getproductfil = createAsyncThunk(
  "product/getproductfil",
  async (formdata, thunkAPI) => {
    try {
      const data = await ProductService.getProductFil(formdata);
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addproduct = createAsyncThunk(
  "product/addproduct",
  async (formdata, thunkAPI) => {
    try {
        const data = await ProductService.addProduct(formdata);
        return {productdata: data};
    } catch (error) {
      const message = 
      (error.response && 
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(); 
    }
  }
);

export const importexcel = createAsyncThunk(
  "product/importexcel",
  async (formdata, thunkAPI) => {
    try {
        const data = await ProductService.importExcel(formdata);
        return {productdata: data};
    } catch (error) {
      const message = 
      (error.response && 
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(); 
    }
  }
);

export const viewproduct = createAsyncThunk(
  "product/viewproduct",
  async (id, thunkAPI) => {
    try {
      const data = await ProductService.viewProduct(id);
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateproduct = createAsyncThunk(
  "product/updateproduct",
  async ({formdata, id}, thunkAPI) => {
    try {
      const data = await ProductService.updateProduct(formdata, id);
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteproduct = createAsyncThunk(
  "product/deleteproduct",
  async ({pid}, thunkAPI) => {
    try {
      const data = await ProductService.deleteProduct(pid);
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

// Bulkdelete

export const bulkdeleteproduct = createAsyncThunk(
  "product/bulkdeleteproduct",
  async (formdata, thunkAPI) => {
    try {
      const data = await ProductService.bulkProductdelete(formdata);
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        // if (error.response && error.response.status === 401) {
        //   EventBus.dispatch("logout");
        // }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

// Category

export const getcategory = createAsyncThunk(
  "product/getcategory",
  async ( thunkAPI) => {
    try {
      const data = await ProductService.getCategory();
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getsubcategory = createAsyncThunk(
  "product/getsubcategory",
  async ( thunkAPI) => {
    try {
      const data = await ProductService.getSubCategory();
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getotp = createAsyncThunk(
  "product/getotp",
  async ( thunkAPI) => {
    try {
      const data = await ProductService.getOTP();
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const postotp = createAsyncThunk(
  "product/postotp",
  async ( otp, thunkAPI) => {
    try {
      const data = await ProductService.postOTP(otp);
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    product: [],
    viewProduct: null,
    message:"",
    category:[],
    subCategory:[],
}

const productSlice = createSlice({
  name: "product",
  initialState,
  extraReducers: {
    [getproductall.fulfilled]: (state, action) => {
      state.product = action.payload.productdata?.data.data
      state.message = action.payload.productdata?.data.message
    },
    [getproductall.rejected]: (state, action) => {
      state.message = action.payload.productdata.data.message
    },
    [getproductfil.fulfilled]: (state, action) => {
      state.product = action.payload.productdata.data.data
      state.message = action.payload.productdata.data.message
    },
    [getproductfil.rejected]: (state, action) => {
      state.message = action.payload.productdata.data.message
    },
    [addproduct.fulfilled]: (state, action) => {
      const pr = action.payload.productdata.data.data
      state.product = [...state.product, pr]
      state.category = [...state.category, pr.main_category]
      state.subCategory = [...state.subCategory, pr.sub_category]
      state.message = action.payload.productdata.data.message
    },
    [addproduct.rejected]: (state, action) => {
      state.message = action.payload.productdata.data.message
    },
    [importexcel.fulfilled]: (state, action) => {
      state.message = action.payload.productdata.data.message
    },
    [importexcel.rejected]: (state, action) => {
      state.message = action.payload.productdata.data.message
    },
    [viewproduct.fulfilled]: (state, action) => {
      state.viewProduct = action.payload.productdata.data.data
      state.message = action.payload.productdata.data.message
    },
    [viewproduct.rejected]: (state, action) => {
      state.viewProduct = action.payload.productdata.data.data
      state.message = action.payload.productdata.data.message
    },
    [updateproduct.fulfilled]: (state, action) => {
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.product = state.product.map((item) =>
          item.product_uuid === id ? action.payload.productdata.data.data : item
        );
      };
      state.message = action.payload.productdata.data.message
    },
    [updateproduct.rejected]: (state, action) => {
      state.message = action.payload.productdata.data.message
    },
    [deleteproduct.fulfilled]: (state, action) => {
      const {
        arg: { pid },
      } = action.meta;
      if (pid) {
        state.product = state.product.filter((item) => item.product_uuid !== pid);
      };
      state.message = action.payload.productdata.data.message
    },
    [deleteproduct.rejected]: (state, action) => {
      state.message = action.payload.productdata.data.message
    },
    [bulkdeleteproduct.fulfilled]: (state, action) => {
      // state.message = action.payload.productdata.data.message
    },
    [bulkdeleteproduct.rejected]: (state, action) => {
      state.message = action.payload.productdata.data.message
    },
    [getcategory.fulfilled]: (state, action) => {
      state.category = action.payload.productdata.data.data
      state.message = action.payload.productdata.data.message
    },
    [getcategory.rejected]: (state, action) => {
      state.message = action.payload.productdata.data.message
    },
    [getsubcategory.fulfilled]: (state, action) => {
      state.subCategory = action.payload.productdata.data.data
      state.message = action.payload.productdata.data.message
    },
    [getsubcategory.rejected]: (state, action) => {
      state.message = action.payload.productdata.data.message
    },
  },
});

const { reducer } = productSlice;
export default reducer;