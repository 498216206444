import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getQuote = (formdata) => {
  return axios.get(API_URL + "/quotes/fetch", {params: formdata, headers: authHeader()});
};


const viewQuote = (id) => {
  return axios.get(API_URL + `/quotes/show/${id}`, { headers: authHeader() })
}

const updateQuote = (formdata, id) => {
  return axios.put(API_URL + `/quotes/update/${id}`, formdata, { headers: authHeader() })
}

const deleteQuote = (id) => {
  return axios.delete(API_URL + `/quotes/delete/${id}`, { headers: authHeader() })
}

const QuoteService = {
  deleteQuote,
  getQuote,
  viewQuote,
  updateQuote
};

export default QuoteService