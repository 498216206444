import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomTable from '../../components/table/Table';
import Viewquote from './Viewquote';
import { useDispatch, useSelector } from "react-redux";
import { getquote } from '../../slices/quote';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    boxShadow: "0px 0px 10px #d3d3d3"
  }));

const columns = [
  { id: 'quotes_id', label: 'Q.NO', minWidth: 100, align:'left' },
  { id: 'quote_order_status', label: 'STATUS', minWidth: 100, align:'left' },
  { id: 'cust_name', label: 'CLIENT', minWidth: 150, align:'left' },
  { id: 'main_category', label: 'MAIN CATEGORY', minWidth: 150, align:'center' },
  { id: 'product', label: 'SUB CATEGORY', minWidth: 150, align:'center' },
  { id: 'printing_style', label: 'PRINTING STYLE', minWidth: 150, align:'center' },
  { id: 'delivery_date', label: 'DELIVERY DATE', minWidth: 150, align:'center' },
  { id: 'action', label: 'ACTION', minWidth: 100, align:'center', actionType: [{view: true, delete: true}] },
];

const rows = [
  {qno: '01', status:'green', client: 'Peter', maincategory:'Reusable Cups', subcategory: 'Pine', printingstyle: 'Single Colour', issuedate: '22/02/2024'},
  {qno: '02', status:'green', client:'Peter', maincategory:'Reusable Cups', subcategory: 'Pine', printingstyle: 'Single Colour', issuedate: '22/02/2024'},
];

const Quote = ({setLoader}) => {

    const [viewquote, setViewquote] = useState(false);
    const [filterval, setFilterval] = useState({
      is_delivered: "",
      quotes_id: "",
      quote_order_status:""
    });

    const handleSubmit = (event) => {
      event.preventDefault()
      setLoader(true)
          dispatch(getquote(filterval))
          .unwrap()
          .then(() => {
            setLoader(false);
          })
          .catch(()=>{
            setLoader(false);
          })
  }

  const quote = useSelector((state) => state.quote);

  const dispatch = useDispatch();

  useEffect(()=>{
    setLoader(true)
      dispatch(getquote())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])
  return (
    <div className="content">
      <Box>
        <Grid container spacing={2} sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={12} sx={{paddingTop: 0}}>
          <Typography variant="h4" gutterBottom>
       Quote
      </Typography>
          </Grid>
          <Grid item xs={12} >
            <Item>
            <Typography variant="h6" gutterBottom>
       Filters
      </Typography>
      <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
      <Grid container spacing={2}>
        <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker sx={{
        width:'100%'
    }} label="Quote Date"
    onChange={e => setFilterval({...filterval, date:e.format('YYYY-MM-DD')})}
                  required
    />
    </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Quote Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Quote Status"
          onChange={e => setFilterval({...filterval, quote_order_status:e.target.value})}
                  required
                  value={filterval.quote_order_status}        >
          <MenuItem value="Confirmed">Confirmed</MenuItem>
          <MenuItem value='Not Confirmed'>Not Confirmed</MenuItem>
        </Select>
      </FormControl>
        </Grid>
        <Grid item xs={3}>
        <TextField sx={{width:'100%'}} id="outlined-basic" label="Quote Number" variant="outlined"
         onChange={e => setFilterval({...filterval, quotes_id:e.target.value})}
                  required
                  type="text"
                  value={filterval.quotes_id} />
        </Grid>
        <Grid item xs={3}>
        <Button type='submit' variant="contained" size="large" sx={{width:'100%', height:'100%', backgroundColor:'#52B4AE'}}>Search</Button>
        </Grid>
      </Grid>
      </Box>
            </Item>
          </Grid>
          <Grid item xs={12} >
            <Item>
      <Grid container spacing={2} sx={{alignItems: 'center'}}>
      <Grid item xs={4}>
        </Grid>
        <Grid item xs={6} sx={{textAlign: 'end', paddingTop: 0}}>
            {viewquote && <Viewquote setViewquote={setViewquote} setLoader={setLoader}/>}
          </Grid>
          <Grid item xs={12}>
          <Box>
        <CustomTable table="quote" columns={columns} rows={quote.quote} setViewquote={setViewquote} setLoader={setLoader}/>
      </Box>
          </Grid>
      </Grid>
            </Item>
          </Grid>

        </Grid>
      </Box>
    </div>
  );
}

export default Quote;