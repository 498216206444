import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getDashboardcount = () => {
  return axios.get(API_URL + "/products/dashboardCount", { headers: authHeader() });
};

const DashboardService = {
    getDashboardcount
  };
  
  export default DashboardService