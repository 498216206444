import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import productReducer from "./slices/product"
import quoteReducer from "./slices/quote"
import dashboardReducer from "./slices/dashboard"

const reducer = {
  auth: authReducer,
  message: messageReducer,
  product: productReducer,
  quote: quoteReducer,
  dashboard: dashboardReducer
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
})

export default store;