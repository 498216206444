import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { RequestQuote, Inventory } from "@mui/icons-material";
import Dashimg from '../../assets/images/dash_image.png';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useSelector, useDispatch } from "react-redux";
import { getdashboardcount } from "../../slices/dashboard";
import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(3),
  color: theme.palette.text.secondary,
}));

const Dashboard = ({setLoader}) => {

  const userName = localStorage.getItem('user');

  const dispatch = useDispatch();

  const dashboard = useSelector((state)=> state.dashboard);

  useEffect(()=>{
    setLoader(true)
    dispatch(getdashboardcount())
    .then(() => {
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  },[])

  return (
    <div className="content">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Item sx={{ height: "100%", backgroundColor:'#EBF3FE' }}>
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', height: '100%'}}>
              <div>
                <Typography variant="h6" gutterBottom sx={{color:'#000'}}>
                  Welcome back {JSON.parse(userName)?.name}! <br></br>
                  Check Dashboard
                </Typography>
              </div>
              <div >
                <img src={Dashimg} alt="dashimg"></img>
              </div>
              </div>
            </Item>
          </Grid>
          <Grid item xs={4}>
          <Link to='/quote' style={{textDecoration:'none'}}>
            <Item sx={{backgroundColor:'#52B4AE', color:'#fff', py: 6}}>
              <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
              <div>
                <Typography variant="h6" gutterBottom>
                {dashboard?.count?.total_quotes}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Number of Quote
                </Typography>
              </div>
              <div>
                  <RequestQuote sx={{fontSize: 30}}/>
              </div>
              </div>
            </Item>
            </Link>
            <Link to='/products' style={{textDecoration:'none'}}>
            <Item sx={{marginTop: 2, background:'#52B4AE', color:'#fff', py: 6}}>
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
              <div>
                <Typography variant="h6" gutterBottom>
                  {dashboard?.count?.total_products}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Number of Products
                </Typography>
              </div>
              <div>
              <Inventory sx={{fontSize: 30}}/>
              </div>
              </div>
            </Item>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Dashboard;
