import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch } from "react-redux";
import { updatequote } from "../../slices/quote";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: '95%',
  bgcolor: "background.paper",
  boxShadow: 12,
  p: 4,
  overflowY:'scroll',
};

export default function Viewquote({ setViewquote, setLoader }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    setViewquote(false);
  };

  React.useEffect(() => {
    setOpen(true);
  }, []);

  const { viewQuote } = useSelector((state) => state.quote);

  const initialState = {
    main_category: viewQuote?.main_category,
    product: viewQuote?.product,
    quantity: viewQuote?.quantity,
    printing_style: viewQuote?.printing_style,
    delivery_date: viewQuote?.delivery_date,
    cust_name: viewQuote?.cust_name,
    company_event_name: viewQuote?.company_event_name,
    cust_mobile: viewQuote?.cust_mobile,
    cust_email: viewQuote?.cust_email,
    quote_order_status: viewQuote?.quote_order_status,
    address: viewQuote?.address,
    is_already_artwork: viewQuote?.is_already_artwork,
    file_link: viewQuote?.file_link,
  };

  const [formdata, setFormdata] = React.useState(initialState);

  const handleSubmit = (event) => {
      event.preventDefault()
      setLoader(true)
      dispatch(updatequote({formdata, id: viewQuote.quotes_uuid}))
          .unwrap()
          .then(() => {
            setLoader(false);
            setOpen(false);
            setViewquote(false);
            setFormdata(initialState);
          })
          .catch(()=>{
            setLoader(false);
          })
  }

  React.useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll'}}
      >
        <Box sx={style}>
          <Grid container sx={{ paddingBottom: 2 }}>
            <Grid item xs={6} sx={{ textAlign: "start" }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                View Quote
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "end" }}>
              <CloseIcon
                onClick={handleClose}
                sx={{ cursor: "pointer" }}
              ></CloseIcon>
            </Grid>
          </Grid>
          <hr></hr>
          <div style={{ marginTop: "30px" }}>
          <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  sx={{ marginBottom: 1 }}
                  fullWidth
                  id="outlined-basic"
                  label="Main Category"
                  variant="outlined"
                  onChange={e => setFormdata({...formdata, main_category:e.target.value})}
                  required
                  type="text"
                  value={formdata.main_category}
                  InputProps={{
            readOnly: true,
          }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ marginBottom: 1 }}
                  fullWidth
                  id="outlined-basic"
                  label="Products (or) Size"
                  variant="outlined"
                  onChange={e => setFormdata({...formdata, product:e.target.value})}
                  required
                  type="text"
                  value={formdata.product}
                  InputProps={{
            readOnly: true,
          }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ marginBottom: 1 }}
                  fullWidth
                  id="outlined-basic"
                  label="Quantity"
                  variant="outlined"
                  onChange={e => setFormdata({...formdata, quantity:e.target.value})}
                  required
                  type="text"
                  value={formdata.quantity}
                  InputProps={{
            readOnly: true,
          }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ marginBottom: 1 }}
                  fullWidth
                  id="outlined-basic"
                  label="Printing style"
                  variant="outlined"
                  onChange={e => setFormdata({...formdata, printing_style:e.target.value})}
                  required
                  type="text"
                  value={formdata.printing_style}
                  InputProps={{
            readOnly: true,
          }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ marginBottom: 1 }}
                  fullWidth
                  id="outlined-basic"
                  label="Delivery Deadline"
                  variant="outlined"
                  onChange={e => setFormdata({...formdata, delivery_date:e.target.value})}
                  required
                  type="text"
                  value={formdata.delivery_date}
                  InputProps={{
            readOnly: true,
          }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ marginBottom: 1 }}
                  fullWidth
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  onChange={e => setFormdata({...formdata, cust_name:e.target.value})}
                  required
                  type="text"
                  value={formdata.cust_name}
                  InputProps={{
            readOnly: true,
          }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ marginBottom: 1 }}
                  fullWidth
                  id="outlined-basic"
                  label="Company or Event Name"
                  variant="outlined"
                  onChange={e => setFormdata({...formdata, company_event_name:e.target.value})}
                  required
                  type="text"
                  value={formdata.company_event_name}
                  InputProps={{
            readOnly: true,
          }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ marginBottom: 1 }}
                  fullWidth
                  id="outlined-basic"
                  label="Phone Number"
                  variant="outlined"
                  onChange={e => setFormdata({...formdata, cust_mobile:e.target.value})}
                  required
                  type="text"
                  value={formdata.cust_mobile}
                  InputProps={{
            readOnly: true,
          }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ marginBottom: 1 }}
                  fullWidth
                  id="outlined-basic"
                  label="Email ID"
                  variant="outlined"
                  onChange={e => setFormdata({...formdata, cust_email:e.target.value})}
                  required
                  type="text"
                  value={formdata.cust_email}
                  InputProps={{
            readOnly: true,
          }}
                />
              </Grid>
              <Grid item xs={6}>
              <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Quote Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Quote Status"
          onChange={e => setFormdata({...formdata, quote_order_status:e.target.value})}
                  required
                  value={formdata.quote_order_status}        >
          <MenuItem value="Confirmed">Confirmed</MenuItem>
          <MenuItem value="Not Confirmed">Not Confirmed</MenuItem>
        </Select>
      </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Address"
                  multiline
                  rows={4}
                  onChange={e => setFormdata({...formdata, address:e.target.value})}
                  required
                  type="text"
                  value={formdata.address}
                  InputProps={{
            readOnly: true,
          }}
                />
              </Grid>
              <Grid item xs={12}>
              <a href={formdata.is_already_artwork ==="no" ? "#" : formdata.is_already_artwork ==="helpme" ? "#" : formdata.file_link} rel="noreferrer" target="_blank">
              <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="File Link"
                  multiline
                  rows={2}
                  onChange={e => setFormdata({...formdata, file_link:e.target.value})}
                  required
                  type="text"
                  value={formdata.is_already_artwork ==="no" ? "No" : formdata.is_already_artwork ==="helpme" ? "Help Me" : formdata.file_link}
                  InputProps={{
            readOnly: true,
          }}
          sx={{
            color: '#fff'
          }}
                />
              </a>
              </Grid>
            </Grid>
            <Button
            type="submit"
              size="large"
              sx={{ marginTop: 5, width: "100%", backgroundColor: "#52B4AE" }}
              variant="contained"
            >
              Update
            </Button>
            </Box>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
