import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const addProduct = (data) => {
  return axios.post(API_URL + "/products/add", data, { headers: authHeader() });
};

const importExcel = (data) => {
  return axios.post(API_URL + "/products/import-excel", data, { headers: authHeader() });
};

const getProductAll = () => {
  return axios.get(API_URL + "/products/fetchall", { headers: authHeader() });
};

const getProductFil = (formdata) => {
  return axios.get(API_URL + "/products/fetch", {params: formdata, headers: authHeader()});
};

const viewProduct = (id) => {
  return axios.get(API_URL + `/products/show/${id}`, { headers: authHeader() })
}

const updateProduct = (formdata, id) => {
  return axios.put(API_URL + `/products/update/${id}`, formdata, { headers: authHeader() })
}

const deleteProduct = (id) => {
  return axios.delete(API_URL + `/products/delete/${id}`, { headers: authHeader() })
}

const bulkProductdelete = (data) => {
  console.log(data);
  return axios.delete(API_URL + '/products/bulkDelete', {data, headers: authHeader() } )
}

// Category
const getCategory = () => {
  return axios.get(API_URL + "/products/main-categories", { headers: authHeader() });
};

const getSubCategory = () => {
  return axios.get(API_URL + "/products/sub-categories", { headers: authHeader() });
};

const getOTP = () => {
  return axios.get(API_URL + "/products/clearAll/otpGen", { headers: authHeader() });
};


const postOTP = (data) => {
  console.log(data);
  return axios.post(API_URL + "/products/clearAll/verifyOtp", data, { headers: authHeader() });
};

const ProductService = {
  addProduct,
  getProductAll,
  viewProduct,
  updateProduct,
  deleteProduct,
  getCategory,
  getSubCategory,
  getProductFil,
  importExcel,
  bulkProductdelete,
  getOTP,
  postOTP
};

export default ProductService