import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import DashboardService from "../services/dashboard.service";

import EventBus from "../utils/EventBus";

export const getdashboardcount = createAsyncThunk(
  "product/getdashboardcount",
  async (thunkAPI) => {
    try {
      const data = await DashboardService.getDashboardcount();
      return { dashboarddata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    count: null,
    message:"",
}

const dashboardSlice = createSlice({
  name: "product",
  initialState,
  extraReducers: {
    [getdashboardcount.fulfilled]: (state, action) => {
      state.count = action.payload.dashboarddata.data.data
      state.message = action.payload.dashboarddata.data.message
    },
    [getdashboardcount.rejected]: (state, action) => {
      state.message = action.payload.dashboarddata.data.message
    }
  },
});

const { reducer } = dashboardSlice;
export default reducer;