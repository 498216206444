import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import VisibilityIcon from "@mui/icons-material/Visibility";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import { deleteproduct, viewproduct } from "../../slices/product";
import { deletequote, viewquote } from "../../slices/quote";

export default function CustomTable({
  setLoader,
  columns,
  rows,
  setViewquote,
  setViewproduct,
  table,
  bulkdelete,
  setBulkdelete
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleView = (pid) => {
    if (table === "quote") {
      setLoader(true);
      dispatch(viewquote(pid))
      .unwrap()
          .then(() => {
            setLoader(false);
            setViewquote(true);
          })
          .catch(()=>{
            setLoader(false);
          })
    }
    if (table === "product"){
      setLoader(true);
      dispatch(viewproduct(pid))
      .unwrap()
          .then(() => {
            setLoader(false);
           setViewproduct(true);
          })
          .catch(()=>{
            setLoader(false);
          })
    }
  };

  const handleDelete = (pid) => {
    if (table === "quote") {
      setLoader(true);
      dispatch(deletequote({pid}))
      .unwrap()
          .then(() => {
            setLoader(false);
          })
          .catch(()=>{
            setLoader(false);
          })
    }
    if (table === "product"){
      setLoader(true);
      dispatch(deleteproduct({pid}))
      .unwrap()
          .then(() => {
            setLoader(false);
          })
          .catch(()=>{
            setLoader(false);
          })
    }
  }

  const handleCheck = (val) =>{
    const che = bulkdelete.includes(val)
    if(!che){
      setBulkdelete([...bulkdelete, val])
    }
    else{
      setBulkdelete(bulkdelete.filter((v)=> v !== val))
    }
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
            {table !== "quote" &&
              <TableCell padding="checkbox">
                {/* <Checkbox color="primary" /> */}
              </TableCell>}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          <TableRow>
          {rows?.length === 0 && (
                <TableCell
                  colSpan={columns?.length}
                  align="center"
                >
                  No data found
                </TableCell>
              )}
              </TableRow>
            {rows ?
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                    {table !== "quote" &&
              <TableCell padding="checkbox">
                <Checkbox color="primary" checked={bulkdelete.includes(row.product_uuid)? true : false} onClick={()=>handleCheck(row.product_uuid)}/>
              </TableCell>}
                      {columns.map((column) => {
                        const pid = row.product_uuid || row.quotes_uuid;
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "cust_name" ? (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 5,
                                }}
                              >
                                <Avatar sx={{ bgcolor: deepOrange[500] }}>
                                  {value.charAt(0).toUpperCase()}
                                </Avatar>
                                {value} <br></br>
                                {row.cust_email}
                              </span>
                            ) : column.id === "quote_order_status" ? value !== "Not Confirmed" ? <Avatar sx={{ bgcolor: "#02af2f" }}>
                                  <DoneIcon/>
                                </Avatar> : <Avatar sx={{ bgcolor: "#dc0707" }}>
                                <ClearIcon/>
                                </Avatar>  : (
                              <span>{value}</span>
                            )}
                            {column.id === "action" &&
                              column.actionType.map((a, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    {a.view && (
                                      <Button
                                        sx={{
                                          background: "#3d07dc",
                                          marginRight: 1,
                                          height: "30px",
                                          width: "30px",
                                          minWidth: 0,
                                        }}
                                        onClick={()=>handleView(pid)}
                                      >
                                        <VisibilityIcon
                                          sx={{ color: "#fff", fontSize: 17 }}
                                        ></VisibilityIcon>
                                      </Button>
                                    )}
                                    {a.edit && (
                                      <Link>
                                        <Button
                                          sx={{
                                            background: "#3d07dc",
                                            marginRight: 1,
                                            height: "30px",
                                            width: "30px",
                                            minWidth: 0,
                                          }}
                                        >
                                          <EditIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></EditIcon>
                                        </Button>
                                      </Link>
                                    )}
                                    {a.delete && (
                                      <Link>
                                        <Button
                                          sx={{
                                            background: "#dc0707",
                                            height: "30px",
                                            width: "30px",
                                            minWidth: 0,
                                          }}
                                          onClick={()=>handleDelete(pid)}
                                        >
                                          <DeleteIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></DeleteIcon>
                                        </Button>
                                      </Link>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                }): null} 
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
