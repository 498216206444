import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import QuoteService from "../services/quote.service";

import EventBus from "../utils/EventBus";


export const getquote = createAsyncThunk(
  "quote/getquote",
  async (formdata, thunkAPI) => {
    try {
      const data = await QuoteService.getQuote(formdata);
      return { quotedata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const viewquote = createAsyncThunk(
  "quote/viewquote",
  async (id, thunkAPI) => {
    try {
      const data = await QuoteService.viewQuote(id);
      return { quotedata: data  };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatequote = createAsyncThunk(
  "quote/updatequote",
  async ({formdata, id}, thunkAPI) => {
    try {
      const data = await QuoteService.updateQuote(formdata, id);
      return { quotedata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deletequote = createAsyncThunk(
  "quote/deletequote",
  async ({pid}, thunkAPI) => {
    try {
      const data = await QuoteService.deleteQuote(pid);
      return { quotedata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    quote: [],
    message:"",
    viewQuote: null
}

const quoteSlice = createSlice({
  name: "quote",
  initialState,
  extraReducers: {
    [getquote.fulfilled]: (state, action) => {
      state.quote = action.payload.quotedata.data.data
      state.message = action.payload.quotedata.data.message
    },
    [getquote.rejected]: (state, action) => {
      state.message = action.payload.quotedata.data.message
    },
    [viewquote.fulfilled]: (state, action) => {
      state.viewQuote = action.payload.quotedata.data.data
      state.message = action.payload.quotedata.data.message
    },
    [viewquote.rejected]: (state, action) => {
      state.message = action.payload.quotedata.data.message
    },
    [updatequote.fulfilled]: (state, action) => {
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.quote = state.quote.map((item) =>
          item.quotes_uuid === id ? action.payload.quotedata.data.data : item
        );
      };
      state.message = action.payload.quotedata.data.message
    },
    [updatequote.rejected]: (state, action) => {
      state.message = action.payload.quotedata.data.message
    },
    [deletequote.fulfilled]: (state, action) => {
      const {
        arg: { pid },
      } = action.meta;
      if (pid) {
        state.quote = state.quote.filter((item) => item.quotes_uuid !== pid);
      };
      state.message = action.payload.quotedata.data.message
    },
    [deletequote.rejected]: (state, action) => {
      state.message = action.payload.quotedata.data.message
    },
  },
});

const { reducer } = quoteSlice;
export default reducer;