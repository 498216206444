import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Home, RequestQuote, Inventory } from "@mui/icons-material";
import classNames from "classnames";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import logo from "../../assets/images/logo.png";

const Sidebar = () => {
  const [selectmenu, setSelectmenu] = useState("");
  const [subselectmenu, setSubSelectmenu] = useState("");

  const side_menus = [
    {
      id: 1,
      menuName: "Dashboard",
      icon: Home,
      route: "/",
    },
    {
      id: 2,
      menuName: "Quote",
      icon: RequestQuote,
      route: "/quote",
    },
    {
      id: 3,
      menuName: "Products",
      icon: Inventory,
      route: "/products",
    },
    //   {
    //     menuName: 'Products',
    //     icon: Home,
    //     route: null,
    //     subMenus:[{
    //       menuName: 'Main Category',
    //       icon: Home,
    //       route: '/maincategory'
    //     },
    //     {
    //       menuName: 'Products (or) Size',
    //       icon: Home,
    //       route: 'productsorsize'
    //     },
    //     {
    //       menuName: 'Printing Style & Rate',
    //       icon: Home,
    //       route: '/printingstyleandrate'
    //     }
    //   ]
    // },
  ];

  const handleMenu = (val) => {
    setSelectmenu(val);
  };

  const handleSubmenu = (val) => {
    if (val === subselectmenu) {
      setSubSelectmenu("");
    } else {
      setSubSelectmenu(val);
    }
  };

  const val = window.location.pathname;

  useEffect(() => {
    const val = window.location.pathname;
    for (let i = 0; i < side_menus.length; i++) {
      if (val === side_menus[i].route) {
        setSelectmenu(side_menus[i].menuName);
      }
    }
  }, [val]);

  return (
    <div className="sidebar">
      <Box>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px 0px",
          }}
        >
          <img src={logo} alt="logo"></img>
        </div>
        <div>
          {side_menus.map((val, index) => {
            return (
              <React.Fragment key={val.id}>
                {!val.subMenus ? (
                  <Link
                    to={val.route}
                    onClick={() => handleMenu(val.menuName)}
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      className={classNames(
                        "sidemenu",
                        `${selectmenu === val.menuName && "active"}`
                      )}
                      sx={{ py: 2, px: 3 }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <val.icon sx={{ marginRight: 1, color: "#000" }} />
                        <Typography sx={{ color: "#000" }}>
                          {val.menuName}
                        </Typography>
                      </div>
                    </Box>
                  </Link>
                ) : (
                  <>
                    <Box
                      sx={{ pt: 2, px: 3, cursor: "pointer" }}
                      onClick={() => handleSubmenu(val.menuName)}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "13px",
                        }}
                      >
                        <val.icon sx={{ marginRight: 1, color: "#fff" }} />
                        <Typography sx={{ color: "#fff" }}>
                          {val.menuName}
                        </Typography>
                        {val.subMenus && subselectmenu === val.menuName ? (
                          <KeyboardArrowUpIcon
                            sx={{
                              marginRight: 1,
                              color: "#fff",
                              width: "100%",
                            }}
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            sx={{
                              marginRight: 1,
                              color: "#fff",
                              width: "100%",
                            }}
                          />
                        )}
                      </div>
                    </Box>
                    {subselectmenu === val.menuName && (
                      <>
                        {val.subMenus.map((va, index) => {
                          return (
                            <Link
                              key={va.id}
                              to={va.route}
                              onClick={() => handleMenu(va.menuName)}
                            >
                              <Box
                                className={classNames(
                                  "sidemenu",
                                  `${selectmenu === va.menuName && "active"}`
                                )}
                                sx={{ py: 2, px: 5 }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <val.icon
                                    sx={{ marginRight: 1, color: "#fff" }}
                                  />
                                  <Typography sx={{ color: "#fff" }}>
                                    {va.menuName}
                                  </Typography>
                                </div>
                              </Box>
                            </Link>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </React.Fragment>
            );
          })}
        </div>
        <div style={{position: "absolute",
    bottom: 0,
    padding: '20px'}}>
        <Typography sx={{textAlign:'center'}}>
        <small>Powered by</small> <br></br> <a href="https://www.nexarcit.com" target="_blank" rel="noreferrer" style={{fontWeight:'bold', textDecoration:'none', color:'#000'}}>NexArc Information Technology</a>
        </Typography>
        </div>
      </Box>
    </div>
  );
};

export default Sidebar;
