import React,{useEffect} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch} from "react-redux";
import { addproduct, getotp, postotp } from "../../slices/product";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 12,
  p: 4,
};


export default function VerifyOTP({setLoader, rows}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
  setOpen(true);
  setLoader(true);
  dispatch(getotp())
          .unwrap()
          .then(() => {
            setLoader(false);
          })
          .catch(()=>{
            setLoader(false);
          })
  }
  const handleClose = () => setOpen(false);


  const dispatch = useDispatch();

  const [otp, setOtp] = React.useState({ otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6:""});

  const handleSubmit = (event) => {
      event.preventDefault()
      const OTP = {otp: otp.otp1+otp.otp2+otp.otp3+otp.otp4+otp.otp5+otp.otp6}
      console.log(OTP);
      setLoader(true)
          dispatch(postotp(OTP))
          .unwrap()
          .then(() => {
            setLoader(false);
            setOpen(false);
            window.location.reload();
          })
          .catch(()=>{
            setLoader(false);
          })
  }

  const handleChange = (value1, event) => {

    setOtp({ ...otp, [value1]: event.target.value });
  }

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {

        elmnt.target.form.elements[next].focus()
      }
    }
    else {     
        const next = elmnt.target.tabIndex;
        if (next < 6) {
          elmnt.target.form.elements[next].focus();
        }
    }

  }

  return (
    <div>
      <Button
      disabled={rows.length> 0 ? false: true}
        variant="contained"
        size="large"
        onClick={handleOpen}
        sx={{ backgroundColor: "#52B4AE" }}
      >
        Trash all
      </Button>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container sx={{ paddingBottom: 2 }}>
            <Grid item xs={6} sx={{ textAlign: "start" }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Trash all products
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "end" }}>
              <CloseIcon
                onClick={handleClose}
                sx={{ cursor: "pointer" }}
              ></CloseIcon>
            </Grid>
          </Grid>
          <hr></hr>
          <div style={{ marginTop: "30px" }}>
          <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
            <Grid container spacing={2} sx={{justifyContent:'center'}}>
              <Grid item xs={12}>
              <p>Enter OTP </p>
              <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', gap: 10}}>
            <input
            name="otp1"
            type="text"
            autoComplete="off"
            className="otpInput"
            value={otp.otp1}
            onChange={e => handleChange("otp1", e)}
            tabIndex="1" maxLength="1" onKeyUp={e => inputfocus(e)}

          />
          <input
            name="otp2"
            type="text"
            autoComplete="off"
            className="otpInput"
            value={otp.otp2}
            onChange={e => handleChange("otp2", e)}
            tabIndex="2" maxLength="1" onKeyUp={e => inputfocus(e)}

          />
          <input
            name="otp3"
            type="text"
            autoComplete="off"
            className="otpInput"
            value={otp.otp3}
            onChange={e => handleChange("otp3", e)}
            tabIndex="3" maxLength="1" onKeyUp={e => inputfocus(e)}

          />
          <input
            name="otp4"
            type="text"
            autoComplete="off"
            className="otpInput"
            value={otp.otp4}
            onChange={e => handleChange("otp4", e)}
            tabIndex="4" maxLength="1" onKeyUp={e => inputfocus(e)}
          />

          <input
            name="otp5"
            type="text"
            autoComplete="off"
            className="otpInput"
            value={otp.otp5}
            onChange={e => handleChange("otp5", e)}
            tabIndex="5" maxLength="1" onKeyUp={e => inputfocus(e)}
          />
          <input
            name="otp6"
            type="text"
            autoComplete="off"
            className="otpInput"
            value={otp.otp6}
            onChange={e => handleChange("otp6", e)}
            tabIndex="6" maxLength="1" onKeyUp={e => inputfocus(e)}
          />
                </div>
                <p style={{textAlign:'center'}}>OTP was send to your Email. Please Check your login Email account</p>
              </Grid>
            </Grid>
            <Button
              size="large"
              sx={{ marginTop: 5, width: "100%", backgroundColor: "#52B4AE" }}
              variant="contained"
              type="submit"
            >
              Verify OTP
            </Button>
            </Box>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
