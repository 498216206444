import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { updateproduct } from "../../slices/product";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 12,
  p: 4,
};

export default function Viewproduct({ setViewproduct, setLoader }) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setViewproduct(false);
  };

  const dispatch = useDispatch();

  const { viewProduct } = useSelector((state) => state.product);

  const initialState = {
    main_category: viewProduct?.main_category,
    sub_category: viewProduct?.sub_category,
    sku: viewProduct?.sku,
    printing_style: viewProduct?.printing_style,
    start_quantity: viewProduct?.start_quantity,
    end_quantity: viewProduct?.end_quantity,
    price: viewProduct?.price,
  };

  const [formdata, setFormdata] = React.useState(initialState);

  const handleSubmit = (event) => {
      event.preventDefault()
      setLoader(true)
      dispatch(updateproduct({formdata, id: viewProduct.product_uuid}))
          .unwrap()
          .then(() => {
            setLoader(false);
            setOpen(false);
            setViewproduct(false);
            setFormdata(initialState);
          })
          .catch(()=>{
            setLoader(false);
          })
  }

  React.useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container sx={{ paddingBottom: 2 }}>
            <Grid item xs={6} sx={{ textAlign: "start" }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                View Product
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "end" }}>
              <CloseIcon
                onClick={handleClose}
                sx={{ cursor: "pointer" }}
              ></CloseIcon>
            </Grid>
          </Grid>
          <hr></hr>
          <div style={{ marginTop: "30px" }}>
          <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  sx={{ marginBottom: 1 }}
                  fullWidth
                  id="outlined-basic"
                  label="Main Category"
                  variant="outlined"
                  onChange={e => setFormdata({...formdata, main_category:e.target.value})}
                  required
                  type="text"
                  value={formdata.main_category}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ marginBottom: 1 }}
                  fullWidth
                  id="outlined-basic"
                  label="Sub Category"
                  variant="outlined"
                  onChange={e => setFormdata({...formdata, sub_category:e.target.value})}
                  required
                  type="text"
                  value={formdata.sub_category}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ marginBottom: 1 }}
                  fullWidth
                  id="outlined-basic"
                  label="SKU"
                  variant="outlined"
                  onChange={e => setFormdata({...formdata, sku:e.target.value})}
                  required
                  type="text"
                  value={formdata.sku}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ marginBottom: 1 }}
                  fullWidth
                  id="outlined-basic"
                  label="Printing style"
                  variant="outlined"
                  onChange={e => setFormdata({...formdata, printing_style:e.target.value})}
                  required
                  type="text"
                  value={formdata.printing_style}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ marginBottom: 1 }}
                  fullWidth
                  id="outlined-basic"
                  label="Start Quantity"
                  variant="outlined"
                  onChange={e => setFormdata({...formdata, start_quantity:e.target.value})}
                  required
                  type="number"
                  value={formdata.start_quantity}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ marginBottom: 1 }}
                  fullWidth
                  id="outlined-basic"
                  label="End Quantity"
                  variant="outlined"
                  onChange={e => setFormdata({...formdata, end_quantity:e.target.value})}
                  required
                  type="number"
                  value={formdata.end_quantity}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ marginBottom: 1 }}
                  fullWidth
                  id="outlined-basic"
                  label="Price"
                  variant="outlined"
                  onChange={e => setFormdata({...formdata, price:e.target.value})}
                  required
                  type="number"
                  value={formdata.price}
                />
              </Grid>
            </Grid>
            <Button
              size="large"
              sx={{ marginTop: 5, width: "100%", backgroundColor: "#52B4AE" }}
              variant="contained"
              type="submit"
            >
              Update
            </Button>
            </Box>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
