import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import CustomTable from "../../components/table/Table";
import Viewproduct from "./Viewproduct";
import Addproduct from "./Addproduct";
import Addexcel from "./Addexcel";
import { useDispatch, useSelector } from "react-redux";
import {
  bulkdeleteproduct,
  getcategory,
  getproductall,
  getproductfil,
  getsubcategory,
} from "../../slices/product";
import VerifyOTP from "./VerifyOTP";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  boxShadow: "0px 0px 10px #d3d3d3",
}));

const columns = [
  { id: "sku", label: "SKU", minWidth: 100, align: "left" },
  { id: "main_category", label: "MAIN CATEGORY", minWidth: 100, align: "left" },
  { id: "sub_category", label: "SUB CATEGORY", minWidth: 150, align: "left" },
  {
    id: "printing_style",
    label: "PRINTING STYLE",
    minWidth: 150,
    align: "center",
  },
  { id: "start_quantity", label: "SQ", minWidth: 100, align: "center" },
  { id: "end_quantity", label: "EQ", minWidth: 100, align: "center" },
  { id: "price", label: "PRICE", minWidth: 100, align: "center" },
  {
    id: "action",
    label: "ACTION",
    minWidth: 100,
    align: "center",
    actionType: [{ view: true, delete: true }],
  },
];

const rows = [
  {
    sku: "THERMO001",
    maincategory: "Water Bottles",
    subcategory: "Teardrop Bottles",
    printingstyle: "Single Colour",
    sq: "55",
    eq: "99",
    price: "6.02",
  },
  {
    sku: "THERMO002",
    maincategory: "Water Bottles",
    subcategory: "Teardrop Bottles",
    printingstyle: "Single Colour",
    sq: "55",
    eq: "99",
    price: "6.02",
  },
];

const Products = ({ setLoader }) => {
  const [viewproduct, setViewproduct] = useState(false);
  const [ bulkdelete, setBulkdelete] = React.useState([]);
  const [filterval, setFilterval] = useState({
    main_category: "",
    sku: "",
    sub_category: "",
  });

  const product = useSelector((state) => state.product);

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoader(true)
        dispatch(getproductfil(filterval))
        .unwrap()
        .then(() => {
          setLoader(false);
        })
        .catch(()=>{
          setLoader(false);
        })
}

const handleBulkdelete = () => {
  const data = {product_uuids: bulkdelete}
  console.log(data);
  setLoader(true);
  dispatch(bulkdeleteproduct(data))
  .unwrap()
  .then(() => {
    setLoader(false);
    window.location.reload();
  })
  .catch(()=>{
    setLoader(false);
  })
}

  useEffect(() => {
    setLoader(true);
    dispatch(getproductall());
    dispatch(getcategory());
    dispatch(getsubcategory())
      .then(() => {
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  return (
    <div className="content">
      <Box>
        <Grid
          container
          spacing={2}
          sx={{ paddingBottom: 2, alignItems: "center" }}
        >
          <Grid item xs={12} sx={{ paddingTop: 0 }}>
            <Typography variant="h4" gutterBottom>
              Products
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <Typography variant="h6" gutterBottom>
                Filters
              </Typography>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      sx={{ width: "100%" }}
                      id="outlined-basic"
                      label="SKU"
                      variant="outlined"
                      onChange={e => setFilterval({...filterval, sku:e.target.value})}
                  required
                  type="text"
                  value={filterval.sku}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Main Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Quote Status"
                        onChange={e => setFilterval({...filterval, main_category:e.target.value})}
                  required
                  value={filterval.main_category}                      >
                        {product?.category.map((val, index) => {
                          return (
                            <MenuItem key={index} value={val}>
                              {val}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Sub Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Quote Status"
                        onChange={e => setFilterval({...filterval, sub_category:e.target.value})}
                  required
                  value={filterval.sub_category}
                      >
                        {product?.subCategory.map((val, index) => {
                          return (
                            <MenuItem key={index} value={val}>
                              {val}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>{" "}
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#52B4AE",
                      }}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <Grid container spacing={2} sx={{ alignItems: "center" }}>
                <Grid item xs={4}>
                <Box
                sx={{display:'flex', alignItems:'center', gap: 3}}
              >  
                  <Button
                    onClick={()=>handleBulkdelete()}
                    disabled = {bulkdelete.length>0 ? false : true} 
                      variant="contained"
                      size="large"
                      sx={{
                        width: "50%",
                        height: "100%",
                        backgroundColor: "#52B4AE",
                      }}
                    >
                      {bulkdelete.length} Delete
                    </Button>
                    </Box>
                </Grid>
                <Grid item xs={2} sx={{ textAlign: "end", paddingTop: 0 }}>
                  {viewproduct && (
                    <Viewproduct
                      setViewproduct={setViewproduct}
                      setLoader={setLoader}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    textAlign: "end",
                    paddingTop: 0,
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                 <div style={{ marginRight: 20 }}>
                    <VerifyOTP                       rows={product.product}
  setLoader={setLoader}/>
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <Addexcel  setLoader={setLoader}/>
                  </div>
                  <div>
                    <Addproduct setLoader={setLoader} />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <CustomTable
                      table="product"
                      columns={columns}
                      rows={product.product}
                      setViewproduct={setViewproduct}
                      setLoader={setLoader}
                      bulkdelete={bulkdelete}
                      setBulkdelete={setBulkdelete}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Products;
